.App {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

*{
  box-sizing: border-box;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Видео будет адаптироваться по размеру контейнера */
  z-index: -2; /* Размещаем видео за затемняющим слоем */
  pointer-events: none; /* Видео не будет интерактивным */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("Assets/optimized_texture.webp"); /* Затемнение фона */
  z-index: -1; /* Размещаем затемняющий слой за содержимым, но перед видео */
}

.content {
  position: relative;
  z-index: 1; /* Контент будет поверх видео и затемняющего слоя */
  color: white; /* Цвет текста, чтобы он был виден на затемненном фоне */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 300px;
}
