.nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: calc(100% - 40px);
    position: fixed;
    bottom: 20px;
    margin: 0 20px;
    background-color: rgba(245, 245, 245, 0.8);
    border-radius: 50px;
    color: #212121;
}
.icon{
    width: 20px;
    height: 20px;
}
.openMenu{
    background-color: rgba(245, 245, 245, 0.8);
    border-radius: 25px;
    width: calc(100% - 40px);
    padding: 20px;
    left: 20px;
    bottom: 85px; /* Adjust as necessary */
    transform: translateY(160%);
    transition: transform 0.5s ease;
    position: fixed;
    z-index: -10;
}
.openMenu.open {
    transform: translateY(0) ;
}
.menuItem{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.openMenu a {
    font-size: 24px;
    color: #212121;
    font-weight: 700;
    text-decoration: none ;
    text-transform: uppercase;
}