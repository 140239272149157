.button{
    border-radius: 50%;
    background: #212121;
    padding: 10px;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: scale 0.3s ease-in-out;
    &:hover{
        scale: 1.1;
    }
}